import React, { useEffect, useState, useRef } from "react";
import BarChart from "../BarChart";
import { useLocation } from "react-router";
import { ApiCalling } from "../../../Services/pool";
import MultibarChart from "../MultibarChart";
import PieChart from "../PieChart";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import CustomTable from "../../Table/CustomTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveKey } from "../../../Feature/Sidebar/ActiveKeySlice";
import { Socket } from "socket.io-client";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import Chartjs from "../Chartjs";
import generatePdfFromCharts from "../../../Components/Utilities/ExportPDF";

const socket = io(ApiCalling.socketUrl.baseUrl);

const QuestionAnalysis = () => {
  const location = useLocation();
  const stateData = location?.state.data;
  const questionID = stateData?.questionId;
  const questionType = stateData?.questionType;
  const user = useSelector((state) => state.userData.loggedInUser);
  const newChildWindowRef = useRef(null);

  const [options, setOptions] = useState([]);
  const [counts, setCounts] = useState([]);
  const [Answeroptions, setAnswerOptions] = useState([]);
  const [Answercounts, setAnswerCounts] = useState([]);
  const [pieCounts, setPieCounts] = useState([]);
  const [answeByQuestion, setansweByQuestion] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const dispatch = useDispatch();

  const [ansByQuesLabels, setAnsByQuesLabels] = useState([]);
  const [ansByQuesCounts, setAnsByQuesCounts] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  const navigate = useNavigate();

  useEffect(() => {
    getOptioCountGraph();
    if (user.role === "studioteacher") {
      getAnsByQuesGraph();
    }
  }, []);

  useEffect(() => {
    getAnswersByQuestion();
  }, [isChecked]);

  const getOptioCountGraph = async () => {
    const payload = {
      questionId: questionID,
    };
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.getOptionCountChart}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (responseData.status === 200 && responseData.optionsArr.length > 0) {
      let dataArray = [];
      dataArray.push({
        type: "bar",
        label: "Options",
        fill: true,
        data: responseData?.countsArr,
        backgroundColor: "rgba(17,141,255,255)",
      });

      let pieArray = [];
      pieArray.push({
        type: "pie",
        label: "Options",
        fill: true,
        data: responseData?.countsArr,
        backgroundColor: "rgba(17,141,255,255)",
      });

      setOptions(responseData?.optionsArr);
      setCounts(dataArray);
      setPieCounts(pieArray);
    }
  };

  const getAnsByQuesGraph = async () => {
    try {
      const response = await fetch(
        `${ApiCalling.base_url_v1}${ApiCalling.paths.getAnswersByQuestionGraph}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ questionId: questionID }),
        }
      );
      const responseData = await response.json();

      if (responseData.status === 200) {
        if (responseData.studentCount) {
          let dataArray = [];
          dataArray.push({
            type: "bar",
            label: "Options",
            fill: true,
            data: responseData?.studentCount,
            backgroundColor: "rgba(17,141,255,255)",
          });

          setAnsByQuesLabels(responseData?.time);
          setAnsByQuesCounts(dataArray);
        }
      }
    } catch (Err) {
      console.log("Err while geting ansByQues", Err);
    }
  };

  const shareData = async () => {
    // socket.emit("sendStudentResult", answeByQuestion);
    // toast.success("Data is shared with Screen.");
    const result = {
      responded: answeByQuestion.data.length,
      correctAnswer: answeByQuestion?.data.filter((row) => row.isAnswerCorrect)
        .length,
      studentCount: answeByQuestion.studentCount,
    };
    const screenDetails = await window.getScreenDetails();

    if (window.screen.isExtended && screenDetails.screens.length > 1) {
      const secondScreen = screenDetails.screens[1];
      const screenWidth = secondScreen.width;
      const screenHeight = secondScreen.height;
      const screenLeft = secondScreen.left;
      const screenTop = secondScreen.top;

      let query = `?data=${JSON.stringify(result)}`;
      newChildWindowRef.current = window.open(
        `${ApiCalling.extensionUrl}/shareResult` + query,
        "New Child Window",
        `popup,width=${screenWidth},height=${screenHeight},left=${screenLeft},top=${screenTop}`
      );
      newChildWindowRef.current.resizeTo(screenWidth, screenHeight);
      setTimeout(() => {
        newChildWindowRef.current.moveTo(screenDetails.screens[1].left, 0);
      }, [100]);
    }
  };

  const getAnswersByQuestion = async () => {
    setLoader(true)
    const payload = {
      questionId: questionID,
    };
    const response = await fetch(
      `${ApiCalling.base_url_v1}${ApiCalling.paths.getAnswersByQuestion}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (responseData.status === 200) {
      if (responseData?.data !== null && responseData?.data?.length > 0) {
        setansweByQuestion(responseData);
        let optiondata1 = [];
        const newdata = responseData.data;
        for (let index = 0; index < newdata.length; index++) {
          optiondata1.push(newdata[index].studentName);
        }

        let conut1 = [];
        for (let index = 0; index < newdata.length; index++) {
          conut1.push(
            newdata[index].studResponseDuration !== undefined
              ? newdata[index].studResponseDuration
              : 0
          );
        }

        let dataArray = [];
        dataArray.push({
          type: "bar",
          label: "Options",
          fill: true,
          data: conut1,
          backgroundColor: "rgba(17,141,255,255)",
        });
        setAnswerOptions(optiondata1);
        setAnswerCounts(dataArray);
    setLoader(false)
      }
    }else{
    setLoader(false)
      toast.error("Error While Getting Student Responses.")
    }
  };
  const headerData = [
    { header: "Sr.No", field: "srNo" },
    { header: "Student Name", field: "studentName" },
    { header: "Student Name", field: "schoolName" },
    { header: "Class Name", field: "className" },
    { header: "Device ID", field: "deviceId" },
    { header: "Right Ans", field: "rightAns" },
    { header: "Stud Response", field: "studResponseAns" },
    // { header: "Answer", field: "isAnswerCorrect" },
    { header: "Duration", field: "studResponseDuration" },
  ];

  const questionDataList =
    answeByQuestion?.data !== undefined &&
    answeByQuestion?.data !== null &&
    answeByQuestion?.data?.length > 0
      ? answeByQuestion?.data.map((itm, index) => {
          return {
            srNo: index + 1,
            studentName: itm?.studentName,
            rightAns: itm?.rightAns,
            studResponseAns: itm?.studResponseAns
              ? itm?.studResponseAns
              : "Not Submitted",
            isAnswerCorrect: itm?.isAnswerCorrect ? "True" : "False",
            studResponseDuration: itm?.studResponseDuration,
          };
        })
      : [];

  const allStudentListData =
    answeByQuestion?.studentData !== undefined &&
    answeByQuestion?.studentData !== null &&
    answeByQuestion?.studentData?.length > 0
      ? answeByQuestion?.studentData.map((itm, index) => {
          const examResult = answeByQuestion?.data.find(
            (result) => result.studentId === itm.studentId
          );
          const {
            rightAns = "-",
            studResponseAns = "-",
            studResponseDuration = "-",
          } = examResult || {};
          return {
            srNo: index + 1,
            studentName: itm?.name,
            schoolName: itm?.schoolName,
            className: itm?.className,
            deviceId: itm?.deviceId,
            rollNo: itm?.rollNo,
            rightAns: rightAns,
            studResponseAns: studResponseAns,
            // isAnswerCorrect: itm?.isAnswerCorrect ? "True" : "False",
            studResponseDuration: studResponseDuration,
          };
        })
      : [];

  return (
    <>
      <div className="py-4">
        {/* <Container> */}
        <Row style={{ marginLeft: "30px", marginBottom: "20px" }}>
          <Col
            md={12}
            className="d-flex justify-content-between align-items-center"
          >
            <Button
              className="secondaryBtn me-3"
              style={{ fontSize: "15px" }}
              onClick={() => {
                navigate("/dashboard");
                dispatch(
                  setActiveKey({
                    activeKey: 0,
                    subActiveKey: 0,
                    activeTab: "Dashboard",
                  })
                );
              }}
            >
              <Icon.ArrowLeftShort className="me-2" size={18} />
              Back
            </Button>

            <Button
              className="secondaryBtn me-3"
              style={{ fontSize: "15px" }}
              onClick={() =>
                // navigate("/dashboard/shareResult", {
                //   state: answeByQuestion,
                // })
                shareData()
              }
            >
              <Icon.ShareFill className="me-2" size={18} />
              Share Result
            </Button>
          </Col>
        </Row>

        <Row>
          <Col
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Q: {stateData.question}</h2>
          </Col>
        </Row>

        {loader ? (
          <center>
            <Spinner />
          </center>
        ) : (
          <>
            {answeByQuestion?.data?.length > 0 && (
              <div
                className="d-flex justify-content-evenly"
                style={{ margin: "auto", marginBottom: "2%" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "2px solid black",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    width: "30%",
                  }}
                >
                  <span className="">No of student responded</span>
                  <span className="mt-2 fw-bold fs-4">
                    {answeByQuestion?.data?.length} /{" "}
                    {answeByQuestion.studentCount}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "2px solid black",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    width: "30%",
                  }}
                >
                  <span className="">Correct Answer</span>
                  <span className="mt-2 fw-bold fs-4">
                    {(
                      (answeByQuestion?.data.filter(
                        (row) => row.isAnswerCorrect
                      ).length /
                        answeByQuestion?.data.length) *
                      100
                    )?.toFixed(2)}{" "}
                    %
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "2px solid black",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    width: "30%",
                  }}
                >
                  <span className="">Wrong Answer</span>
                  <span className="mt-2 fw-bold fs-4">
                    {(
                      (answeByQuestion?.data.filter(
                        (row) => row.isAnswerCorrect === false
                      ).length /
                        answeByQuestion?.data.length) *
                      100
                    ).toFixed(2)}
                    %
                  </span>
                </div>
              </div>
            )}

            <Button
              className="primaryBtn ms-auto mb-4 mt-2"
              onClick={() => generatePdfFromCharts("charts", "Question_Report")}
            >
              <Icon.FiletypePdf size={18} className="me-2" />
              Export Reports
            </Button>
            <Row
              className="gx-5 px-0 px-md-5 justify-content-center"
              id="charts"
            >
              {questionType === "mcq" ? (
                <Col md={12}>
                  {counts !== undefined &&
                  counts !== null &&
                  counts?.length > 0 ? (
                    <>
                      <Chartjs
                        chartLabels={options}
                        chartData={counts}
                        stacked={false}
                        chartHeading="Question option wise count"
                        height="300px"
                        isModal={false}
                      />
                      <br />
                      <br />
                    </>
                  ) : (
                    <p className="text-center">Loading ...</p>
                  )}
                </Col>
              ) : (
                <Col md={12} id="chart2">
                  {pieCounts !== undefined &&
                  pieCounts !== null &&
                  pieCounts?.length > 0 &&
                  options.length > 0 ? (
                    <>
                      <Chartjs
                        chartLabels={options}
                        chartData={pieCounts}
                        stacked={false}
                        chartHeading="Question option wise count"
                        height="300px"
                        isModal={false}
                      />
                      <br />
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              )}

              {user.role === "studioteacher" && (
                <Col md={10} id="chart3">
                  {ansByQuesCounts !== undefined &&
                  ansByQuesCounts !== null &&
                  ansByQuesCounts?.length > 0 ? (
                    <>
                      <Chartjs
                        chartLabels={ansByQuesLabels}
                        chartData={ansByQuesCounts}
                        stacked={false}
                        chartHeading="Answers by Question Graph"
                        height="300px"
                        isModal={false}
                      />
                      <br />
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                </Col>
              )}

              {user.role !== "studioteacher" ? (
                <>
                  <Col md={10} id="chart4">
                    <Form style={{ alignSelf: "flex-end", marginTop: "5%" }}>
                      <Form.Check
                        size={100}
                        type="switch"
                        id="custom-switch"
                        label={
                          isChecked ? (
                            <h5>The student provided a correct answer</h5>
                          ) : (
                            <h5>The student provided a wrong answer</h5>
                          )
                        }
                        checked={isChecked}
                        onChange={handleToggle}
                      />
                    </Form>
                    {counts !== undefined &&
                    counts !== null &&
                    counts?.length > 0 ? (
                      <>
                        <Chartjs
                          chartLabels={Answeroptions}
                          chartData={Answercounts}
                          stacked={true}
                          chartHeading={
                            isChecked
                              ? "Student Roll Number wise Duration for correct Answers"
                              : "Student Roll Number wise Duration for wrong Answers"
                          }
                          height="300px"
                          isModal={false}
                        />
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </Col>
                </>
              ) : null}
            </Row>

            {user.role !== "studioteacher" && (
              <Card
                style={{
                  // width: "80%",
                  margin: "auto",
                  marginTop: "3%",
                  marginBottom: "8%",
                }}
              >
                <div>
                  <u>
                    <h4
                      className="text-center"
                      style={{ fontWeight: "600", margin: "2%" }}
                    >
                      Question and Answer
                    </h4>
                  </u>
                </div>

                <div>
                  {allStudentListData.length > 0 && (
                    <CustomTable
                      headerData={headerData}
                      headerRowData={allStudentListData}
                    />
                  )}
                </div>
              </Card>
            )}
            {/* </Container> */}
          </>
        )}
      </div>
    </>
  );
};

export default QuestionAnalysis;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiCalling } from "../../Services/pool";
import { io } from "socket.io-client";
import { Col, Container, Row, Spinner } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import labels from "../../config/labels.json";
import {
  setEndQuestion,
  setStartQuestion,
} from "../../Feature/Sidebar/Questionslice";
import { useNavigate } from 'react-router-dom';

const GetLiveQuestion = () => {

    const socket = io(ApiCalling.socketUrl.baseUrl);
    const user = useSelector((state) => state.userData.loggedInUser);
    const newChildWindowRef = useSelector(
        (state) => state.question.questionChildRef
      );
      const [loader, setLoader] = useState(false);
      const [question, setQuestion] = useState(null);
      const [showAns, setShowAns] = useState();
      const [endLoader, setEndLoader] = useState(false);
      const selectedLang = useSelector((state) => state.userData.selectedLanguage);
      const navigate = useNavigate();
      
  const dispatch = useDispatch();

    useEffect(() => {
        getSocketData();
        // setQuestion(
        //     {
        //         "_id": "66667b9aa8cc0b928c0a3ca6",
        //         "schoolId": "1",
        //         "options": [
        //             "true",
        //             "false"
        //         ],
        //         "questionId": "QUESTIONID1717992346544",
        //         "lmsLiveSessionId": 126,
        //         "classId": "4",
        //         "subjectId": "2",
        //         "topicId": "1",
        //         "question": "What is test data?",
        //         "questionType": "truefalse",
        //         "questionAns": [
        //             {
        //                 "_id": "66667c8ed859807e2454d356",
        //                 "classId": "4",
        //                 "schoolId": "1",
        //                 "options": [
        //                     "true",
        //                     "false"
        //                 ],
        //                 "isAnsGiven": true,
        //                 "isSyncWithLMS": false,
        //                 "isSyncWithHub": false,
        //                 "isDelete": false,
        //                 "questionStudAnsId": "QUESTIONSTUDANSID1717992590660",
        //                 "questionId": "QUESTIONID1717992346544",
        //                 "question": "What is test data?",
        //                 "rightAns": "true",
        //                 "subjectId": "2",
        //                 "topicId": "1",
        //                 "studentId": "STUDENTID_21_1707041202031",
        //                 "studentName": "Manmohan Acharya",
        //                 "questionTime": "2024-06-10T04:09:20.129Z",
        //                 "deviceId": "21",
        //                 "studResponse": "A",
        //                 "studResponseAns": "true",
        //                 "studResponseTime": "2024-06-10T04:09:38.405Z",
        //                 "studResponseDuration": 18.276,
        //                 "isAnswerCorrect": true,
        //                 "createdAt": "2024-06-10T04:09:50.665Z",
        //                 "updatedAt": "2024-06-10T04:09:50.665Z",
        //                 "__v": 0
        //             },
        //             {
        //                 "_id": "66667c8ed859807e2454d35e",
        //                 "classId": "4",
        //                 "schoolId": "1",
        //                 "options": [
        //                     "true",
        //                     "false"
        //                 ],
        //                 "isAnsGiven": true,
        //                 "isSyncWithLMS": false,
        //                 "isSyncWithHub": false,
        //                 "isDelete": false,
        //                 "questionStudAnsId": "QUESTIONSTUDANSID1717992590960",
        //                 "questionId": "QUESTIONID1717992346544",
        //                 "question": "What is test data?",
        //                 "rightAns": "true",
        //                 "subjectId": "2",
        //                 "topicId": "1",
        //                 "studentId": "STUDENTID_1_1707041202031",
        //                 "studentName": "Sanchit Pradhan",
        //                 "questionTime": "2024-06-10T04:09:20.129Z",
        //                 "deviceId": "01",
        //                 "studResponse": "B",
        //                 "studResponseAns": "false",
        //                 "studResponseTime": "2024-06-10T04:09:43.950Z",
        //                 "studResponseDuration": 23.821,
        //                 "isAnswerCorrect": false,
        //                 "createdAt": "2024-06-10T04:09:50.962Z",
        //                 "updatedAt": "2024-06-10T04:09:50.962Z",
        //                 "__v": 0
        //             }
        //         ]
        //     }
        // )
      }, []);
    
      const getSocketData = (questionId) => {
        setLoader(true);
        console.log("Socket Calling");
        socket.emit("getAnsResponce", {
          classId: user.classId,
          schoolId: user.schoolId,
        });
        console.log("data", {
          classId: user.classId,
          schoolId: user.schoolId,
        });
        socket.on("questionRes", (data) => {
          console.log("Socket response", data);
          setQuestion(data[0]);
          setLoader(false);
        });
      };

      console.log("ques", question);

    //   const showCorrectAnswer = () => {
    //     setShowAns(true);
    //    if(window.screen.isExtended){
    //     let query = `?question=${JSON.stringify({
    //       ...question,
    //       showAnswer: true,
    //     })}`;
    //     newChildWindowRef.current.location.href =
    //       `${ApiCalling.extensionUrl}/singlequiz` + query;
    //    }
    //   };

    const HandleEndTest = () => {
        if (!loader) {
          endTest();
          closeExtendedScreenFromDifferentPage();
        }
      };

      const endTest = async () => {
        setEndLoader(true);
        let link =
          user?.role == "studioteacher"
            ? "/qsa/endQuestion"
            : "/qsa/endQuestion/classteacher";
        const payload = { QuestionId: question.questionId };
        try {
          const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          });
    
          const responseData = await response.json();
          if(responseData.status === 200){
            console.log("Clsoign Test Now");
            dispatch(setEndQuestion({}));
            setEndLoader(false);
            toast.success("Test End");
            navigate("/dashboard/questionAnalysis", {
              state: { data: question },
            });
          }
          
        } catch (error) {
          console.error("Error:", error);
        }
      };

      const closeExtendedScreenFromDifferentPage = () => {
        dispatch(setEndQuestion({}));
        if (window.screen.isExtended && newChildWindowRef) {
          newChildWindowRef.current.close();
        } else {
          console.error("Parent window not found or closed.");
        }
      };

  return (
    <div className=' kbc GetLiveQuestion'>
        { 
            loader ? <div className='text-center'> 
                        <h1>Searching for live question ...</h1><Spinner variant='dark'/>
                    </div>
                :
            !question ? 
                <h1>No Live Question Running</h1>
            :
            <Container>
                <h1 className='text-center'>Live Question</h1>
                <Row className="justify-content-center gy-4 mt-3">
              <Col xs={12}>
                <div className="question">
                  <h5>{question?.question}</h5>
                </div>
              </Col>

              {question?.options &&
                question?.options.map((option, index) => (
                  <Col md={5} key={index} style={{ cursor: "pointer" }}>
                    <div
                      className={`answer ${
                        showAns &&
                        question.rightAns === question.options[index]
                          ? "correct"
                          : ""
                      }`}
                      // onClick={() => handleOptionClick(index)}
                    >
                      <h5>{option}</h5>
                    </div>
                  </Col>
                ))}
            </Row>

            <Row className="mx-3" style={{ marginTop: "10%" }}>
              {/* <Col md={2}>
                <button
                  className="primaryBtn p-3"
                  onClick={() => {
                    showCorrectAnswer();
                  }}
                >
                  {labels.questions[selectedLang].showAnswer}
                </button>
              </Col> */}

              <Col className="d-flex justify-content-end">
                {endLoader ? (
                  <Spinner />
                ) : (
                  <button
                    className="primaryBtn p-3"
                    onClick={() => {
                      HandleEndTest();
                    }}
                  >
                    {labels.questions[selectedLang].endQuestion}
                  </button>
                )}
              </Col> 
            </Row>
            </Container>
        }
        
    </div>
  )
}

export default GetLiveQuestion;
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ApiCalling } from "../../Services/pool";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const MultipleQueQuiz = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.userData.loggedInUser);

  const currentIndex = useSelector((state) => state.activeData);
  const sessionDetails = useSelector((state) => state.userData.sessionDetails);

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const location = useLocation();
  const classId = location.state.class;
  const subject = location.state.subject;
  const topic = location.state.topic;
  const [loader, setLoader] = useState(false);
  const [questionLoader, setQuestionLoader] = useState([]);
  const [timer, setTimer] = useState();

  // function to handle next question
  const handleNextClick = () => {
    localStorage.removeItem("initialTime");

    // alert("Are you shure to end the test ?")
    endTest(questions, currentQuestionIndex);
    insertValues();
    if (currentQuestionIndex < questions.length - 1) {
      // setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      // localStorage.setItem('currentIndex', JSON.stringify(currentQuestionIndex + 1))
      // setTimer(30);
      setIsPlaying(true);
    }
  };

  // function to handle prev question
  const handlePrevClick = () => {
    localStorage.removeItem("initialTime");
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
      setIsPlaying(true);
    }
  };

  // fetching time from API
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentQuestionIndex < questions.length - 1) {
        // setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        if (questions[currentQuestionIndex + 1]?.displayTime != undefined) {
          setTimer(
            parseInt(questions[currentQuestionIndex + 1]?.displayTime, 10)
          );
          setIsPlaying(true);
        }
      }
    }, parseInt(questions[currentQuestionIndex]?.displayTime, 10) * 1000);

    // if (questions.length > 0) {
    //   setTimer(parseInt(questions[currentQuestionIndex]?.displayTime, 10));
    //   localStorage.setItem(
    //     "initialTime",
    //     JSON.stringify(
    //       parseInt(questions[currentQuestionIndex]?.displayTime, 10)
    //     )
    //   );
    // }

    return () => clearTimeout(timer);
  }, [currentQuestionIndex, questions]);

  // get question list
  useEffect(() => {
    getAllQuestionList();
  }, []);

  // Get All Question List
  const getAllQuestionList = async () => {
    setQuestionLoader(true);
    try {
      const payload = {
        classId: classId,
        subjectId: subject,
        topicId: topic,
        testId: location.state.test.testId,
      };
      try {
        const response = await fetch(
          `${ApiCalling.base_url_v1}/questions/getQuestionList`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          }
        );

        const responseData = await response.json();
        console.log("responseData--->119", responseData);
        if (responseData.status === 200 && responseData.data.length > 0) {
          setQuestions(responseData?.data);
          setQuestionLoader(false);
        } else {
          setQuestionLoader(false);
          setQuestions([]);
          navigate("/dashboard/testList");
        }
      } catch (error) {
        console.error("Error:", error);
        setQuestionLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const getAllQuestionList = async () => {
  //   const payload = {
  //     classId: classId,
  //     subjectId: subject,
  //     topicId: topic,
  //   };
  //   dispatch(getMultipleQustion(payload),)
  // };

  useEffect(() => {
    if (questions.length > 0) {
      console.log("STarting Test", questions[currentQuestionIndex]);
      startTest(questions[currentQuestionIndex]);
    }
  }, [questions, currentQuestionIndex]);

  // Question List
  const questionList =
    questions?.length > 0 &&
    questions?.map((que, index) => {
      return que?.question;
    });

  // Answer List
  const aswerList =
    questions?.length > 0 &&
    questions?.map((que) => {
      return que.options;
    });

  // API calling before test start

  const startTest = async (item) => {
    setLoader(true);
    const payload = {
      QuestionId: item.questionId,
      liveSessionId: sessionDetails ? sessionDetails?.lmsSessionId : "",
    };
    try {
      let link =
        user?.role == "studioteacher"
          ? "/qsa/startQuestion/oldsession"
          : "/qsa/startQuestion/classteacher";
      const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      if (responseData.Error) {
        toast.error(responseData.Error.Message);
        setLoader(false);
        window.history.back();
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      window.history.back();
      setLoader(false);
    }
  };

  // API calling after test end
  useEffect(() => {
    if (timer === 1) {
      endTest(questions, currentQuestionIndex);
    } else if (timer === 0 && currentQuestionIndex === questions.length) {
      navigate("/dashboard/testSubmit");
    }
  }, [timer]);

  const endTest = useCallback(async (queArray, index) => {
    console.log("endQuestionID", queArray[index]);
    const payload = { QuestionId: queArray[index].questionId };
    try {
      let link =
        user?.role == "studioteacher"
          ? "/qsa/endQuestion"
          : "/qsa/endQuestion/classteacher";
      const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      toast.success("Test End");
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  const HandleEndTest = () => {
    endTest(questions, currentQuestionIndex);
    setTimeout(() => {
      navigate("/dashboard/testSubmit");
    }, 1000);
  };

  const insertValues = () => {
    // Check if currentQuestionIndex is valid
    if (currentQuestionIndex !== null && currentQuestionIndex !== undefined) {
      // Create a copy of the questions array
      // const updatedQuestions = [...questions];

      // Add or update the desired field in the current question object
      // updatedQuestions[currentQuestionIndex] = {
      //   ...updatedQuestions[currentQuestionIndex],
      //   questionDisplayed: true,
      // };
      questions[currentQuestionIndex].questionDisplayed = true;

      console.log("updatedQuestions", questions);
      // Update the state with the modified questions array
      setQuestions([...questions]);
    }
  };

  // *********************

  const initialTime = localStorage.getItem("initialTime"); // Initial time in seconds
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    if (timer !== null) {
      const storedTimestamp = localStorage.getItem("timerStartTimestamp");
      const currentTimeStamp = Math.floor(Date.now() / 1000);
      console.log("Started Timer", timer, storedTimestamp);

      if (
        !storedTimestamp ||
        currentTimeStamp - storedTimestamp > initialTime
      ) {
        localStorage.setItem("timerStartTimestamp", currentTimeStamp);
        setTimeRemaining(initialTime);
      } else {
        const remainingTime =
          initialTime - (currentTimeStamp - storedTimestamp);
        setTimeRemaining(remainingTime);

        const intervalId = setInterval(() => {
          setTimeRemaining((prevTime) => prevTime - 1);

          // Check if timeRemaining is 1, and show an alert
          if (timeRemaining !== 1) {
            setIsPlaying(false);
            if (currentQuestionIndex < questions.length - 1) {
              // setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
              setTimer(
                parseInt(questions[currentQuestionIndex + 1]?.displayTime, 10)
              );
              setIsPlaying(true);
            }
            // alert('Timer reached 1 second!');
          }
        }, 1000);

        return () => clearInterval(intervalId);
      }
    }
  }, [initialTime, timeRemaining]);

  return (
    <>
      <div className="kbc py-5 d-flex">
        {questionLoader ? (
          <center>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <Spinner /> <br />
              <h4>Loading your question please wait ...</h4>
            </div>
          </center>
        ) : !loader ? (
          <Container
          // style={{ width: "70%" }}
          >
            <Row className="d-flex justify-content-between align-items-center">
              <Col className="d-flex justify-content-between align-items-center">
                {questions?.length > 1 ? (
                  <div>
                    <Icon.ArrowLeftCircleFill
                      size={30}
                      className={`arrowLeft ${
                        currentQuestionIndex === 0 ? "disabled" : ""
                      }`}
                      data-toggle="tooltip"
                      title="Prev"
                      onClick={handlePrevClick}
                      disabled={currentQuestionIndex === 0}
                    />
                  </div>
                ) : (
                  <div></div>
                )}

                {questions[currentQuestionIndex]?.displayTime != "" ? (
                  <div className="p-3 d-flex flex-column align-items-center">
                    <div className="timerContainer">
                      <p>{timer}</p>
                    </div>
                  </div>
                ) : (
                  <div style={{ marginTop: "8%" }}></div>
                )}

                {questions?.length > currentQuestionIndex + 1 ? (
                  <div>
                    <Icon.ArrowRightCircleFill
                      size={30}
                      className="arrowRight"
                      data-toggle="tooltip"
                      title="Next"
                      onClick={() => handleNextClick()}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </Col>
            </Row>

            <Row className="justify-content-center gy-4 mt-3">
              <Col xs={12}>
                <div className="question">
                  <h5>{questionList[currentQuestionIndex]}</h5>
                </div>
              </Col>

              {aswerList[currentQuestionIndex]?.map((option, index) => (
                <Col md={5} key={index} style={{ cursor: "pointer" }}>
                  <div className="answer">
                    <h5>{option}</h5>
                  </div>
                </Col>
              ))}
            </Row>

            <Row md={12} className="mx-3" style={{ marginTop: "10%" }}>
              <Col className="d-flex justify-content-end">
                <button
                  className="primaryBtn p-3"
                  onClick={() => {
                    HandleEndTest();
                  }}
                >
                  End test
                </button>
              </Col>
            </Row>
          </Container>
        ) : (
          <center>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <Spinner /> <br />
              <h4>Loading your question please wait ...</h4>
            </div>
          </center>
        )}
      </div>
    </>
  );
};

export default MultipleQueQuiz;

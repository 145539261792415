import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ApiCalling } from "../../Services/pool";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import labels from "../../config/labels.json";
import { setEndQuestion } from "../../Feature/Sidebar/Questionslice";
import { setChildRef } from "../../Feature/Sidebar/Questionslice";
import NoLive from '../../Assets/NoLive.svg';

const socket = io(ApiCalling.socketUrl.baseUrl);

const SessionWiseQueAnsList = () => {
  const [questions, setQuestions] = useState([]);
  const [extendQuestion, setExtendQuestion] = useState("");
  const [isExtendScreen, setIsExtendScreen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [subject, setSubject] = useState();
  const [topic, setTopic] = useState();
  const [classData, setClassData] = useState();
  const [studentCount, setstudentCount] = useState([]);
  const [questionId, setquestionId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userData.loggedInUser);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const extendRef = useRef(null);
  const newChildWindowRef = useSelector(
    (state) => state.question.questionChildRef
  );

  useEffect(() => {
    getSocketData();
    // socket.off("questionRes");
  }, []);
  const getSocketData = (questionId) => {
    console.log("Socket Calling");
    socket.emit("getAnsResponce", {
      classId: user.classId,
      schoolId: user.schoolId,
    });
    console.log("data", {
      classId: user.classId,
      schoolId: user.schoolId,
    });
    socket.on("questionRes", (data) => {
      console.log("Socket response", data);
      setSubject(data[0].subjectId);
      setClassData(data[0].classId);
      setTopic(data[0].topicId);
      setquestionId(data[0]?.questionId);
      setstudentCount(data[data.length - 1]?.studentsCount);
      setExtendQuestion(data[0]);
      setQuestions(data);
      // dispatch(setLiveQues);
    });
  };

  const Findgreen = (question) => {
    return (
      <div
        className="d-flex mt-4 "
        style={{
          flexDirection: "column",
          border: "1px solid #D8D7D7",
          borderRadius: "4px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            // alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {studentCount?.length > 0 ? (
            studentCount?.map((row, index) => {
              let Isgreen = question.filter((item) => {
                if (item.deviceId == row.deviceId) {
                  return true;
                } else {
                  return false;
                }
              });
              Isgreen = Isgreen.length == 0 ? false : true;

              return (
                <div
                  key={index}
                  className="liveStudent"
                  style={{
                    color: Isgreen ? "white" : "black",
                    backgroundColor: Isgreen ? "#048B02" : "#DDDDDD",
                  }}
                >
                  {row.rollNo} - {row.name.split(" ")[0]}
                </div>
              );
            })
          ) : (
            <center>
              <h4>Waiting for Answers....</h4>
              <br />
              <Spinner />
            </center>
          )}
        </div>
      </div>
    );
  };

  const endTest = async () => {
    setLoader(true);
    let link =
      user?.role == "studioteacher"
        ? "/qsa/endQuestion"
        : "/qsa/endQuestion/classteacher";
    const payload = { QuestionId: questionId };
    try {
      const response = await fetch(`${ApiCalling.base_url_v1}${link}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const responseData = await response.json();
      if (responseData.status === 200) {
        closeExtendedScreenFromDifferentPage();
        setLoader(false);
        navigate("/dashboard/questionAnalysis", {
          state: {
            data: {
              questionId: questionId,
              questionType: questions[0].questionType,
              question: questions[0].question,
            },
          },
        });
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
    }
  };

  const closeExtendedScreenFromDifferentPage = () => {
    dispatch(setEndQuestion({}));
    if (window.screen.isExtended && newChildWindowRef) {
      newChildWindowRef.current.close();
    } else {
      console.error("Parent window not found or closed.");
    }
  };

  const getUniqueIds = (data) => {
    const uniqueDeviceIds = new Set();

    // Filter the array to get unique elements based on deviceId
    const uniqueElements = data.filter((item) => {
      const deviceId = item.deviceId;

      // Check if deviceId is not in the set
      if (!uniqueDeviceIds.has(deviceId)) {
        // Add deviceId to the set
        uniqueDeviceIds.add(deviceId);

        // Return true to include the item in the result
        return true;
      }

      // Return false to exclude duplicates
      return false;
    });

    return uniqueElements;
  };

  const extendScreen = async () => {
    const screenDetails = await window.getScreenDetails();
    console.log("---screen -----", window.screen.isExtended);
    console.log("---screen -----", screenDetails);
    if (window.screen.isExtended && screenDetails.screens.length > 1) {
      const secondScreen = screenDetails.screens[1];
      const screenWidth = secondScreen.width;
      const screenHeight = secondScreen.height;
      const screenLeft = secondScreen.left;
      const screenTop = secondScreen.top;

      let query = `?question=${JSON.stringify({
        ...extendQuestion,
        showAnswer: false,
      })}`;
      extendRef.current = window.open(
        `${ApiCalling.extensionUrl}/singlequiz` + query,
        "New Child Window",
        `popup,width=${screenWidth},height=${screenHeight},left=${screenLeft},top=${screenTop}`
      );
      dispatch(setChildRef(extendRef));

      extendRef.current.resizeTo(screenWidth, screenHeight);

      setTimeout(() => {
        extendRef.current.moveTo(screenDetails.screens[1].left, 0);
      }, [100]);
      setIsExtendScreen(true);
    }
  };

  return (
    <Container className="kbc d-flex" style={{ flexDirection: "column" }}>
      
      {questions?.length === 1 &&
        <Row className="justify-content-center align-items-center" style={{height:'80vh'}}>
          <Col md={12} className="text-center ">
              <img src={NoLive} alt="No Live session" className="img-fluid w-25"/>
            <h1 className="mt-3">
              {selectedLang === "odia"
                ? "ବର୍ତ୍ତମାନ ପାଇଁ କ Live ଣସି ଲାଇଭ୍ ପୋଲ୍ ଅଧିବେଶନ ଉପଲବ୍ଧ ନାହିଁ |"
                : "No Live Poll Sessions available for now"}
            </h1>
          </Col>
          </Row>
        }
      

        {/* {!questions.length > 1 && (
          <h1 className="text-center mt-4">
          {selectedLang === "odia"
            ? "ବର୍ତ୍ତମାନ ପାଇଁ କ Live ଣସି ଲାଇଭ୍ ପୋଲ୍ ଅଧିବେଶନ ଉପଲବ୍ଧ ନାହିଁ |"
            : "No Live Poll Sessions available for now"}
        </h1>
        )} */}
      <Row className="d-flex justify-content-center align-items-center">
        <>
          <div className="liveSession">
            {questions.length > 1 && !isExtendScreen && (
                  <Button
                    className="primaryBtn ms-auto"
                    onClick={() => {
                      extendScreen();
                    }}
                  > <Icon.WindowSplit className="me-2"/>
                    Extend Screen
                  </Button>
            )}


            {
               questions.map((row, index)=>{
                if (index !== questions.length - 1) {
                  return (
                    <>
                    <Card className="mt-4">
                      <Card.Header><h5 className="mb-0">Question- {row.question}</h5></Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={2}>
                            <p>Class ID - {classData}</p>
                          </Col>
                          <Col md={2}>
                            <p>Subject Id - {subject}</p>
                          </Col>
                          <Col md={2}>
                            <p>Topic Id - {topic}</p>
                          </Col>
                          <Col md={3}>
                            <p>Students in the class - {studentCount?.length}</p>
                          </Col>
                          <Col md={3}>
                            <p> Students attended the test -{" "}
                            {getUniqueIds(row.questionAns).length}</p>
                          </Col>
                          <Col md={4}>
                              <p>Students not attended the test -
                              {studentCount?.length -
                                getUniqueIds(row.questionAns)?.length}
                                </p>
                        </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <h4 className="mt-5 mb-0 text-center">Student Answer Report</h4>
                    {Findgreen(row.questionAns)}
                    </>
                  )
                }
              })
            }
  
  
          </div>
        </>
      </Row>
      {questions.length > 1 && (
        <Row className="py-5 d-flex justify-content-center align-items-center">
          {loader ? (
            <center>
              <Spinner />
            </center>
          ) : (
            <div className="d-flex justify-content-end align-items-end">
              <Button
                className="primaryBtn"
                onClick={() => {
                  endTest();
                }}
              >
                End Test
              </Button>
            </div>
          )}
        </Row>
      )}
    </Container>
  );
};

export default SessionWiseQueAnsList;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllQuestionList } from "../Questions/slice/AllQuestionSlice";
import { setChildRef } from "../../Feature/Sidebar/Questionslice";
import { Button, Col, Container, Row, Form, Card, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import CustomTable from "../../Common/Table/CustomTable";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiCalling, environments } from "../../Services/pool";
import labels from "../../config/labels.json";
import moment from "moment";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";


const QuestionsNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newChildWindowRef = useRef(null);
  const user = useSelector((state) => state.userData.loggedInUser);
  const selectedLang = useSelector((state) => state.userData.selectedLanguage);
  const [searchQuestion, setSearchQuestion] = useState();
  const [filterList, setFilterList] = useState();
  const allQuestionDataList = useSelector(
    (state) => state.allQuestions.AllQuestion
  );
  const [questionList, setQuestionList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateErr, setStartDateErr] = useState("");
  const [endDateErr, setEndDateErr] = useState("");
  const [excelLoader,setExcelLoader] = useState(false)

  const getAllquestion = async () => {
    setLoader(true);
    const payload =
      user.role === "classteacher"
        ? {
            classId: user?.classId?user?.classId:4,
          }
        : {};
    try {
      const response = await fetch(
        `${ApiCalling.base_url_v1}${ApiCalling.paths.getQuestionsList}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();
      if (responseData.status === 200) {
        setFilterList(responseData.data);
        setQuestionList(responseData);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(responseData.error);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoader(false);
      toast.error(error);
    }
    // dispatch(getAllQuestionList(payload));
  };

  const handleClick = () => {
    const dataToSend = { classId: "4", subjectId: "5", studentId: "12" };

    // Construct the query string
    const queryString = new URLSearchParams(dataToSend).toString();

    // Use navigate to change the URL with query parameters
    navigate(`/dashboard/diplayquestiontoStudent?${queryString}`);
  };

  useEffect(() => {
    getAllquestion();
  }, []);

  // useEffect(() => {
  //   console.log(allQuestionDataList);
  //   if (allQuestionDataList?.data?.length > 0) {
  //     setFilterList(allQuestionDataList.data);
  //   }
  // }, []);

  // Send a message to the child window to expose the close function
  window.addEventListener("message", (event) => {
    if (event.data === "closeExtendedScreen") {
      closeExtendedScreen();
      window.close();
    }
  });

  const secondDislay = async (itm) => {
    console.log("Calling start Test");
    const screenDetails = await window.getScreenDetails();
    console.log("---screen -----", window.screen.isExtended);
    console.log("---screen -----", screenDetails);
    if (window.screen.isExtended && screenDetails.screens.length > 1) {
      const secondScreen = screenDetails.screens[1];
      const screenWidth = secondScreen.width;
      const screenHeight = secondScreen.height;
      const screenLeft = secondScreen.left;
      const screenTop = secondScreen.top;

      let query = `?question=${JSON.stringify({ ...itm, showAnswer: false })}`;
      newChildWindowRef.current = window.open(
        `${ApiCalling.extensionUrl}/singlequiz` + query,
        "New Child Window",
        `popup,width=${screenWidth},height=${screenHeight},left=${screenLeft},top=${screenTop}`
      );
      dispatch(setChildRef(newChildWindowRef));
      newChildWindowRef.current.resizeTo(screenWidth, screenHeight);

      setTimeout(() => {
        newChildWindowRef.current.moveTo(screenDetails.screens[1].left, 0);
      }, [100]);
      // Use postMessage to send a message to the child window
      const messageData = { action: "startQuiz", data: itm };
      newChildWindowRef.current.postMessage(messageData, "*");

      navigate("/dashboard/singlequiz", {
        state: { data: itm },
      });
    } else {
      navigate("/dashboard/singlequiz", {
        state: { data: itm },
      });
    }
  };

  const closeExtendedScreen = () => {
    if (newChildWindowRef.current && !newChildWindowRef.current.closed) {
      newChildWindowRef.current.close();
    }
  };

  // for table data
  const headerData = [
    { header: labels.questions[selectedLang].srNo, field: "srNo" },
    { header: labels.questions[selectedLang].question, field: "question" },
    { header: labels.questions[selectedLang].class, field: "class" },
    { header: labels.questions[selectedLang].subject, field: "subject" },
    {
      header: labels.questions[selectedLang].createdDate,
      field: "createdDate",
    },
    { header: labels.questions[selectedLang].action, field: "action" },
  ];

  const questionDataList =
    filterList !== undefined && filterList !== null && filterList.length > 0
      ? filterList.map((itm, index) => {
          return {
            srNo: index + 1,
            question: itm?.question,
            class: itm?.className,
            subject: itm?.subjectName,
            createdDate: moment(itm?.createdAt).format("DD-MMM-YYYY"),
            action: (
              <div>
                <span className="mb-2 d-flex">
                  <Button
                    className="primaryBtn"
                    onClick={() => {
                      secondDislay(itm);
                    }}
                  >
                    {labels.questions[selectedLang].startTest}
                  </Button>
                </span>
                {/* <span className="d-flex justify-content-center">
                  <Button
                    className="primaryBtn w-50"
                    onClick={() =>
                      handleClick()
                    }>
                    Student Test
                  </Button>
                </span> */}
              </div>
            ),
          };
        })
      : [];

  const getSearchQuestion = (searchValue) => {
    let filter = questionList.data.filter((row) => {
      return (
        row.question
          .toLowerCase()
          .startsWith(searchValue.toLowerCase().trim()) ||
        row.question.toLowerCase().includes(searchValue.toLowerCase().trim())
      );
    });

    setFilterList(filter);
  };

  const searchByStartEndValidate = () => {
    let isValid = true;
    if (!startDate) {
      isValid = false;
      setStartDateErr(true);
    } else if (!endDate) {
      isValid = false;
      setEndDateErr(true);
    }
    return isValid;
  };

  const searchByDate = () => {
    if (searchByStartEndValidate()) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);

      let filter = questionList.data.filter((row) => {
        const createdAt = new Date(row.createdAt);
        return createdAt >= start && createdAt <= end;
      });

      console.log("Date Filter", filter, startDate, endDate);
      setFilterList(filter);
    }
  };

  const uploadExcel = (event) => {
    if (event.target.files.length > 0) {
      event.preventDefault();
      setExcelLoader(true);
      var files = event.target.files,
        f = files[0];
      var reader = new FileReader();
      reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: "binary" });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        /* Convert array to json*/
        const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
        // console.log(dataParse);
        excelUploadReport(dataParse);
        // setFileUploaded(dataParse);
      };
      reader.readAsBinaryString(f);
      document.getElementById("fileInput").value = "";
    } else {
      toast.error("Please Upload Excel first");
    }
  };

  const excelUploadReport = async (data) => {
    console.log(data);
    let studentArr = [];
    for (let i = 12; i < data.length - 1; i++) {
      let questionArr = [];
      for (let j = 6; j < data[11].length; j++) {
        questionArr.push({
          questionId: data[11][j], //s1-1,s2-2
          studResponseAns:
            data[i][j] === undefined
              ? null
              : typeof data[i][j] === "number"
              ? data[i][j].toString()
              : data[i][j], // student Ans Response
        });
      }
      studentArr.push({
        studentId: data[i][1],
        studentName: data[i][2], //student name
        deviceId:
          typeof data[i][0] === "number" ? data[i][0].toString() : data[i][0], //keypadid
        questionAns: questionArr,
      });
    }
    const questionAnsmodel = {
      testId: data[5][1], //Exam Unique Id
      topicId: data[6][1], //Exame Name
      teacher: data[8][1], //faculty
      subject: data[9][1],
      student: studentArr,
    };
    console.log(questionAnsmodel);

    const response = await fetch(
      `${environments.staging.baseUrl}${ApiCalling.paths.bulkAnswerData}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(questionAnsmodel),
      }
    );
    const responseData = await response.json();
    if (responseData.status === 200) {
      setExcelLoader(false);
      toast.success("Excel Uploaded Successfully");
      navigate("/dashboard");
    } else {
      setExcelLoader(false);
      toast.error(responseData.error);
    }
  };

  return (
    <div className="ViewQuestion py-4">
      <Container>
      <Row>
          <Col md={12} className="d-flex justify-content-end">
            <div>
              <input
                id="fileInput"
                style={{ display: "none" }}
                type="file"
                onChange={(e) => uploadExcel(e)}
              />
              { excelLoader ? (
                <Spinner />
              ) : (
                <label
                  className="primaryBtn me-3 mx-auto"
                  htmlFor="fileInput"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                >
                  <Icon.FileEarmarkSpreadsheetFill className="me-2" size={16} />{" "}
                  Upload Excel
                </label>
              )}
            </div>
            <div>
              <Button
                className="primaryBtn me-3 mx-auto"
                onClick={() => navigate("/dashboard/addque")}
              >
                <Icon.PlusCircleFill className="me-2" size={16} />
                {labels.questions[selectedLang].addQuestion}
              </Button>
            </div>
          </Col>
        </Row>
        {questionDataList.length > 0 && (
          <Row className="justify-content-center pt-3">
            <Col md={4}>
              <Card className="searchCard">
                <Form.Label className="searchLabel">
                  {labels.questions[selectedLang].searchByQuestion}
                </Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label className="">
                    {labels.questions[selectedLang].searchQuestion}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={searchQuestion}
                    name="question"
                    onChange={(e) => {
                      getSearchQuestion(e.target.value);
                    }}
                    placeholder={labels.questions[selectedLang].enterQuestion}
                  />
                </Form.Group>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="searchCard">
                <Form.Label className="searchLabel">
                  {labels.questions[selectedLang].seachByDate}
                </Form.Label>
                <Row className="d-flex justify-content-center align-items-end">
                  <Col md={4} className="mb-3 mb-md-0">
                    <Form.Label>
                      {labels.questions[selectedLang].seachByStartDate}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={startDate}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        setStartDate(
                          moment(e.target.value).format("YYYY-MM-DD")
                        );
                        setEndDate("");
                        e.target.value.length > 0 && setStartDateErr(false);
                      }}
                    />
                    {startDateErr ? (
                      <p className="errMsg">
                        {labels.questions[selectedLang].selectStartDate}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col md={4} className="mb-3 mb-md-0">
                    <Form.Label>
                      {labels.questions[selectedLang].seachByEndDate}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={endDate}
                      min={startDate ? startDate : ""}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => {
                        setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                        e.target.value.length > 0 && setEndDateErr(false);
                      }}
                    />
                    {endDateErr ? (
                      <p className="errMsg">
                        {labels.questions[selectedLang].selectEndDate}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col md={2}>
                    <Button className="btn btn-submit" onClick={searchByDate}>
                      <Icon.Search className="me-2" size={14} />
                      {labels.questions[selectedLang].search}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
        <hr className="w-100 mb-0" />
        { loader ? <center>
          <Spinner />
        </center> :  questionDataList.length > 0 ? (
          <CustomTable
            headerData={headerData}
            headerRowData={questionDataList}
          />
        ) : (
          <div className="mt-5 d-flex justify-content-center align-items-center">
            <h5>No Questions Found</h5>
          </div>
        )}
      </Container>
    </div>
  );
};

export default QuestionsNew;

export const environments = {
  main: {
    baseUrl: "https://serverapi.odisa.foxberry.live/v1",
  },
  staging: {
    baseUrl: "https://serverapi.odisa.foxberry.live/v1",
  },
  local: {
    baseUrl: "https://clientapi.odisa.foxberry.live/v1",
  },
};

export const ApiCalling = {
  base_url_v1: "https://clientapi.odisa.foxberry.live/v1",
  paths: {
    login: "/users/login",
    AddQuestions: "/questions/addQuestions",
    getQuestionsList: "/questions/getQuestionList",
    getStudentAnswers: "/qsa/getStudentAnswers",
    getStudentWiseGraph: "/graph/studentWiseGraph",
    getAnswerPieChart: "/graph/answersGraph",
    getOptionCountChart: "/graph/questionOptionCountGraph",
    getAnswersByQuestionGraph: "/graph/AnswersByQuestionGraph",
    getAnswersByQuestion: "/qsa/getAnswersByQuestion",
    createSession: "/session/addSession",
    getSessionList: "/session/getAllSessions",
    liveSessionList: "/integration/liveSessionList",
    endLivession: "/integration/endLiveSession",
    getTeacherList: "/users/getUserByRole",
    getClassSubjectToipic: "/questions/getClassSubjectTopic",
    getLMSquestions: "/integration/allQuestionList",
    studioGraph: "/graph/studioGraph",
    studentList: "/student/getAllStudents",
    updateStudent: "/student/updateStudent",
    startSession: "/session/start/session",
    endSession: "/session/end/session",
    AddQuestionsStudioTeacher: "/questions/addQuestions/studioTeacher",
    createTest: "/questions/create/test",
    testList: "/questions/test/list",
    startHandRaise: "/qsa/startHandraise",
    endHandRaise: "/qsa/endHandraise",
    classList: "/integration/classesListDb",
    subjectList: "/integration/subjectsListDb",
    topicList: "/integration/topicsListDb",
    bulkAnswerData: "/qsa/addBulkAnsData",
  },
  socketUrl: {
    baseUrl: "https://clientapi.odisa.foxberry.live",
    // baseUrl: "http://127.0.0.1:7008",
  },
  extensionUrl: "https://client.odisa.foxberry.live",
  // extensionUrl:'http://localhost:3000'
};
